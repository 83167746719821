import React from 'react';

import check_list from '../../../../assets/icons/check_list.svg';
import customer_care from '../../../../assets/icons/customer_care.svg';
import money from '../../../../assets/icons/money.svg';
import shield from '../../../../assets/icons/shield.svg';

import yi_map from '../../../../assets/bgImages/yi-map.png';

function WhyUs() {

    const Item = ({image, title, text}) => {
        return(
            <div className='w-full bg-white rounded-xl shadow-lg p-5' data-aos="fade-up">
                <img src={image} alt='' className='h-[35px] md:h-[55px]' />
                <h3 className='text-xl md:text-2xl font-bold font-montserrat mt-3'>{title}</h3>
                <p className='text-base md:text-lg text-[#838383] mt-1'>{text}</p>
            </div>
        )
    }

    return (
        <div>
            <div className='w-full py-10 lg:py-20 px-[20px]' id='why_us'>
                <div className='text-center'>
                    <h1 className='text-3xl md:text-4xl font-extrabold font-montserrat'>Why Choose Us?</h1>
                    <p className='font-inter text-xl md:text-2xl max-w-[800px] mx-auto mt-2 text-[#636363]'>Discover the benefits that make choosing us the clear advantage</p>
                </div>
                <div className='max-w-[800px] mx-auto mt-10 md:mt-20 gap-8 md:gap-[50px] grid grid-cols-1 md:grid-cols-2'>
                    <Item
                        image={check_list}
                        title={'Flexible Solutions'}
                        text={`No matter the size of your cargo, we can provide flexible bulk consolidation options and tailor-made solutions for you.`}
                    />
                    <Item
                        image={customer_care}
                        title={'Regular Services'}
                        text={`We have been deeply involved in Ghana freight for 7 years and have a rich customer base. We regularly load containers every week and track the progress of cargo transportation, and provide you with feedback at any time.`}
                    />
                    <Item
                        image={money}
                        title={'Competitive Prices'}
                        text={`Our prices are reasonable and competitive, ensuring you get affordable logistics value.`}
                    />
                    <Item
                        image={shield}
                        title={'Reduced Risks'}
                        text={`We are responsible for all incoming goods to ensure that they are finally delivered to customers safely, and are committed to providing safety insurance for every shipment to reduce customers' losses due to accidents.`}
                    />
                </div>
            </div>
            <div className='bg-[#D9E1E9] py-20'>
                <div className='mx-auto max-w-[890px] px-[20px] text-center'>
                    <h1 className='max-w-[450px] mx-auto text-center font-montserrat text-3xl md:text-5xl font-extrabold'>Our Warehouses</h1>
                    <p className='text-[#6363636] text-lg md:text-2xl mt-2'>Locate our warehouses in Ghana and China</p>
                </div>
                <img className='max-w-[1200px] mx-auto mt-20' src={yi_map} alt='' />
            </div>
        </div>
    )
}

export default WhyUs