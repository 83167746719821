import React, { useState } from 'react';

import header_logo from '../../assets/icons/yi_logistics_logo.png';
import { Link } from 'react-router-dom';
import { PATHS } from '../../constants/paths.constants';
import { motion, AnimatePresence } from "framer-motion"
import { FiMenu, FiX } from 'react-icons/fi';

function Header({selected}) {
    const [showNavBar, setShowNavBar] = useState(false);

    return (
        <>
            <div className='bg-white'>
                <div className="max-w-[1200px] mx-auto text-black flex items-center gap-2 py-5 px-[20px]">
                    <Link to={PATHS.HOME}><img src={header_logo} alt='YI LOGISTICS'  className='cursor-pointer w-[130px] md:w-[200px]' /></Link>
                    <div className="flex-1 flex items-center justify-end gap-[42px]">
                        <Link className={`${selected === PATHS.HOME  ? 'font-bold text-primary' : 'text-black'} cursor-pointer font-inter hidden md:block`} to={PATHS.HOME}>Home</Link>
                        <Link className={`${selected === PATHS.ABOUT  ? 'font-bold text-primary' : 'text-black'} cursor-pointer font-inter hidden md:block`} to={PATHS.ABOUT}>About Us</Link>
                        <Link className={`${selected === PATHS.SERVICES  ? 'font-bold text-primary' : 'text-black'} cursor-pointer font-inter hidden md:block`} to={PATHS.SERVICES}>Our Services</Link>
                        <Link className={`${selected === PATHS.WHY_US  ? 'font-bold text-primary' : 'text-black'} cursor-pointer font-inter hidden md:block`} to={PATHS.WHY_US}>Why Yi Logistics</Link>
                        <Link className={`${selected === PATHS.CAREERS  ? 'font-bold text-primary' : 'text-black'} cursor-pointer font-inter hidden md:block`} to={PATHS.CAREERS}>Careers</Link>
                        <Link to={PATHS.CONTACT} ><button className="hidden md:block px-4 py-3 bg-primary text-white rounded-lg font-inter font-semibold">Contact Us</button></Link>
                        <div className='h-[40px] w-[40px] rounded-full bg-[#f5f5f5] md:hidden flex items-center justify-center' onClick={() => setShowNavBar(true)}> <FiMenu /> </div>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {showNavBar && (
                    <motion.div 
                        initial={{ height: '0px' }}
                        animate={{ height: '100vh' }}
                        exit={{ height: '0px' }}
                        transition={{duration: 0.5}}
                        className='h-screen w-full bg-white fixed top-0 z-[1000]'
                    >
                        <div className='w-full h-full'>
                            <div className="max-w-[1200px] mx-auto text-black flex items-center gap-2 py-5 px-[20px]">
                                <Link to={PATHS.HOME}><img src={header_logo} alt='YI LOGISTICS'  className='cursor-pointer w-[130px] md:w-[200px]' /></Link>
                                <div className="flex-1 flex items-center justify-end gap-[42px]">
                                    <div className='h-[40px] w-[40px] rounded-full bg-[#f5f5f5] flex items-center justify-center' onClick={() => setShowNavBar(false)}> <FiX /> </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 mt-10 px-[20px]'>
                                <Link to={PATHS.HOME} className='w-full hover:bg-[#f5f5f5] font-medium text-lg rounded-lg px-2 py-3 text-primary text-center'>Home</Link>
                                <Link to={PATHS.ABOUT} className='w-full hover:bg-[#f5f5f5] font-medium text-lg rounded-lg px-2 py-3 text-primary text-center'>About Us</Link>
                                <Link to={PATHS.SERVICES} className='w-full hover:bg-[#f5f5f5] font-medium text-lg rounded-lg px-2 py-3 text-primary text-center'>Our Services</Link>
                                <Link to={PATHS.WHY_US} className='w-full hover:bg-[#f5f5f5] font-medium text-lg rounded-lg px-2 py-3 text-primary text-center'>Why Yi Logistics</Link>
                                <Link to={PATHS.WAREHOUSES} className='w-full hover:bg-[#f5f5f5] font-medium text-lg rounded-lg px-2 py-3 text-primary text-center'>Warehouses</Link>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default Header