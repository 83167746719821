import React, { useEffect } from 'react'

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import WhyUs from '../home/components/whyUs/WhyUs';
import { PATHS } from '../../constants/paths.constants';

function WhyYiLogistics() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <Header selected={PATHS.WHY_US} />
            <WhyUs />
            <Footer />
        </div>
    )
}

export default WhyYiLogistics