import React, { useEffect } from 'react'

import Footer from '../../components/footer/Footer';


import Hero from './components/hero/Hero';
import IntroBanner from './components/introBanner/IntroBanner';
// import Steps from './components/steps/Steps';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <Hero />
            <IntroBanner />
            {/* <Steps /> */}
            <Footer />
        </div>
    )
}

export default Home