import { PATHS } from './constants/paths.constants';

//pages
import About from './pages/about/About';
import Home from './pages/home/Home';
import ServicesPage from './pages/servicesPage/ServicesPage';
import WhyYiLogistics from './pages/whyYiLogistics/WhyYiLogistics';
import WarehousesPage from './pages/warehousesPage/WarehousesPage';
import ContactPage from './pages/contactPage/ContactPage';

import {
  createBrowserRouter,
} from "react-router-dom";
import Careers from './pages/careers/Careers';
import MarketingOperator from './pages/careers/openings/MarketingOperator';
import SalesExecutive from './pages/careers/openings/SalesExecutive';

export const routes = createBrowserRouter([
  {
    path: PATHS.HOME,
    element: <Home />
  },
  {
    path: PATHS.ABOUT,
    element: <About />
  },
  {
    path: PATHS.SERVICES,
    element: <ServicesPage />
  },
  {
    path: PATHS.WHY_US,
    element: <WhyYiLogistics />
  },
  {
    path: PATHS.WAREHOUSES,
    element: <WarehousesPage />
  },
  {
    path: PATHS.CONTACT,
    element: <ContactPage />
  },
  {
    path: PATHS.CAREERS,
    element: <Careers />
  },
  {
    path: PATHS.MARKETING_OPERATOR,
    element: <MarketingOperator />
  },
  {
    path: PATHS.SALES_EXEC,
    element: <SalesExecutive />
  },
])