import React from 'react'
import Header from '../../../components/header/Header'
import { FiChevronRight } from 'react-icons/fi'
import { PATHS } from '../../../constants/paths.constants'
import { Link } from 'react-router-dom'
import Footer from '../../../components/footer/Footer'

function SalesExecutive() {
    return (
        <div className='overflow-x-hidden'>
            <Header />
            <div className='max-w-[1200px] mx-auto px-[20px] py-20'>
                <div className='flex items-center gap-3 bg-[#f5f5f5] p-2 rounded-lg w-fit px-5'><Link to={PATHS.CAREERS}><p className='font-sm'>Careers</p></Link> <FiChevronRight /> <p className='font-base text-[#044585] font-bold'>Marketing Operator</p> </div>
                <h1 className='text-2xl md:text-5xl font-bold mt-5 font-montserrat'>Sales Executive</h1>
                <div className='mt-5 w-full flex items-center gap-2 md:gap-5'>
                    <div className='px-2 py-1 bg-[#F5F5F5] font-medium text-[#636363] rounded-md text-base md:text-lg'>Greater Accra, Ghana🇬🇭</div>
                    <div className='px-2 py-1 bg-[#F5F5F5] font-medium text-[#636363] rounded-md text-base md:text-lg'>Full-time</div>
                    <div className='px-2 py-1 bg-[#F5F5F5] font-medium text-[#636363] rounded-md text-base md:text-lg'>On-site</div>
                </div>
                <div className='mt-10 pt-10 border-t border-t-[#E0E0E0]'>
                    <div data-aos="fade-up">
                        <h2 className='font-montserrat text-xl md:text-3xl text-[#161C2D] font-medium'>Job Description</h2>
                        <p className='text-base md:text-lg text-[#636363] leading-[150%] md:leading-[150%] mt-2'>We are seeking a dynamic and results-driven Sales Executive to join our team at Yi Shipping. As a Sales Executive, you will be responsible for identifying and pursuing new business opportunities, nurturing existing client relationships, and achieving sales targets. You will leverage your strong understanding of industry trends and market dynamics to develop tailored solutions that meet the unique needs of our clients. With exceptional communication and negotiation skills, you will collaborate closely with cross-functional teams to deliver exceptional service and drive revenue growth.</p>
                    </div>
                    <div className='mt-5' data-aos="fade-up">
                        <h2 className='font-montserrat text-xl md:text-3xl text-[#161C2D] font-medium'>Requirements</h2>
                        <p className='text-base md:text-lg text-[#636363] leading-[150%] md:leading-[150%] mt-2'>Candidate must be a female<br />Must have good communication skills<br />Be a team player<br />Customer satisfaction orientation</p>
                    </div>
                    <div className='mt-5' data-aos="fade-up">
                        <h2 className='font-montserrat text-xl md:text-3xl text-[#161C2D] font-medium'>Level of Education</h2>
                        <p className='text-base md:text-lg text-[#636363] leading-[150%] md:leading-[150%] mt-2'>No form of certification is required</p>
                    </div>
                    <div className='mt-5' data-aos="fade-up">
                        <h2 className='font-montserrat text-xl md:text-3xl text-[#161C2D] font-medium'>Experience Level</h2>
                        <p className='text-base md:text-lg text-[#636363] leading-[150%] md:leading-[150%] mt-2'>Candidate should possess <span className='font-bold'>0 - 6 months of experience in the Logistics space.</span></p>
                    </div>
                    <div className='mt-5' data-aos="fade-up">
                        <h2 className='font-montserrat text-xl md:text-3xl text-[#161C2D] font-medium'>Location Requirements</h2>
                        <p className='text-base md:text-lg text-[#636363] leading-[150%] md:leading-[150%] mt-2'>Candidate must live within these areas; <span className='font-bold'>Madina, 37, Accra Central, Labone and Labadi</span></p>
                    </div>
                    <Link to={PATHS.CONTACT} ><button className="mt-8 px-4 py-3 bg-primary text-white rounded-lg font-inter font-semibold">Apply now</button></Link>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SalesExecutive