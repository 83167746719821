import React, { useEffect } from 'react'

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../components/header/Header'
import { PATHS } from '../../constants/paths.constants';
import team from '../../assets/images/yi-logistics-team.jpg';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function Careers() {
    useEffect(() => {
        AOS.init()
    }, [])

    const Item = ({title, context, link}) => {
        return(
            <Link to={link}>
                <div className='w-full p-5 md:p-7 shadow-lg rounded-xl bg-white'  data-aos="fade-up">
                    <h3 className='text-xl md:text-3xl font-medium font-inter'>{title}</h3>
                    <p className='text-base md:text-lg text-[#636363] mt-3'>{context}</p>
                    <div className='mt-3 w-full flex items-center gap-2 md:gap-5'>
                        <div className='px-2 py-1 bg-[#F5F5F5] font-medium text-[#636363] rounded-md text-base md:text-lg'>Greater Accra, Ghana🇬🇭</div>
                        <div className='px-2 py-1 bg-[#F5F5F5] font-medium text-[#636363] rounded-md text-base md:text-lg'>Full-time</div>
                        <div className='px-2 py-1 bg-[#F5F5F5] font-medium text-[#636363] rounded-md text-base md:text-lg'>On-site</div>
                    </div>
                </div>
            </Link>
        )
    }

    return (
        <div className='overflow-x-hidden'>
            <Header selected={PATHS.CAREERS} />
            <div className='px-[20px] text-center py-20'>
                <h1 className='max-w-[540px] mx-auto text-center font-montserrat text-3xl md:text-5xl font-bold leading-[120%] md:leading-[120%]' data-aos="fade-up">Become a part of the Yi Shipping Family</h1>
                <p className='text-[#161C2D] max-w-[900px] mx-auto text-lg md:text-2xl mt-3' data-aos="fade-up" data-aos-delay="200">Be part of our team and elevate your career while making a meaningful impact on enhancing the logistics and shipping journey for our customers.</p>
            </div>
            <div className='w-full py-20 px-[20px] bg-[#f5f5f5]'>
                <div className='max-w-[1200px] mx-auto flex flex-col md:flex-row items-center gap-[20px] md:gap-10'>
                    <img className='h-[300px] w-full md:flex-1 md:h-[500px] rounded-xl object-cover' src={team} alt='Yi Logistics shipping team' data-aos="fade-up" data-aos-delay="400" />
                    <div className='w-full md:w-[450px]' data-aos="fade-up" data-aos-delay="600">
                        <h3 className='font-montserrat text-2xl md:text-4xl font-medium max-w-[350px]'>Build your career with us</h3>
                        <p className='text-justify leading-[150%] md:leading-[150%] text-base md:text-lg text-[#636363] mt-3'>At Yi Shipping, we cultivate a dynamic atmosphere where your talents, expertise, and knowledge thrive. Joining our family means not only competitive compensation but also performance-based incentives. Our culture fosters continuous learning, knowledge sharing, and empowerment, fostering a sense of belonging and camaraderie among our members. If you're someone eager to express your ideas and innovations, our organisation provides the ideal platform to excel as a web technology professional.</p>
                    </div>
                </div> 
            </div>
            <div className='py-20 px-[20px]'>
                <div className='text-center'>
                    <h1 className='max-w-[540px] mx-auto text-center font-montserrat text-3xl md:text-5xl font-bold leading-[120%] md:leading-[120%]' data-aos="fade-up">Current Openings</h1>
                    <p className='text-[#636363] max-w-[900px] mx-auto text-lg md:text-2xl mt-1' data-aos="fade-up" data-aos-delay="200">For any information regarding career opportunities, email us at <br /> <span className='font-bold'>yishipping.gh@gmail.com</span></p>
                </div>
                <div className='mt-10 max-w-[850px] mx-auto flex flex-col gap-10'>
                    <Item
                        title={'Marketing Operator'}
                        context={'We are looking for a dynamic and results-oriented Marketing operator to join our team at yi shipping. In this role, you’ll be responsible for...'}
                        link={PATHS.MARKETING_OPERATOR}
                    />
                    <Item
                        title={'Sales Executive'}
                        context={'We are looking for a dynamic and results-oriented Sales Executive to join our team at yi shipping. In this role, you’ll be responsible for...'}
                        link={PATHS.SALES_EXEC}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Careers