import React, { useEffect } from 'react';

import { FaPhoneAlt } from 'react-icons/fa';
import { IoMailSharp } from "react-icons/io5";
import { IoMdPin } from "react-icons/io";

import { useForm } from '@formspree/react';

function ContactUs() {

    const [state, handleSubmit] = useForm("mqkrdbyy");
    
    useEffect(() => {
        if(state.errors){
            console.log(state.errors)
        }
    }, [state])
    
    return (
        <div className='w-full px-[20px] py-10 lg:py-20 bg-[#E6ECF3]' id='contact_us'>
            <div className='max-w-[1100px] mx-auto'>
                <div className='text-center'>
                    <h1 className='text-3xl md:text-4xl font-extrabold font-montserrat'>Contact Us</h1>
                    <p className='font-inter text-xl md:text-2xl max-w-[700px] opacity-80 mt-2 mx-auto text-[#636363]'>Reach out to us and experience the best shipping and logistics ever!</p>
                </div>
                <div className='w-full grid lg:grid-cols-[400px_1fr] gap-10 mt-20'>
                    <div className='flex flex-col gap-10' data-aos="fade-right">
                        <div className='flex gap-5'>
                            <div className='h-[50px] w-[50px] bg-white flex items-center justify-center text-primary text-lg rounded-full'><FaPhoneAlt /></div>
                            <div className='flex-1'>
                                <h4 className='text-xl font-bold'>Call us</h4>
                                <p className='text-lg font-inter text-[#161C2D] opacity-80'>🇬🇭+233 53 462 3340 <br /> 🇨🇳+86 13157972275</p>
                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <div className='h-[50px] w-[50px] bg-white flex items-center justify-center text-primary text-2xl rounded-full'><IoMailSharp /></div>
                            <div className='flex-1'>
                                <h4 className='text-xl font-bold'>Email us</h4>
                                <p className='text-lg font-inter text-[#161C2D] opacity-80'>yishipping.gh@gmail.com</p>
                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <div className='h-[50px] w-[50px] bg-white flex items-center justify-center text-primary text-2xl rounded-full'><IoMdPin /></div>
                            <div className='flex-1'>
                                <h4 className='text-xl font-bold'>Visit us</h4>
                                <p className='text-lg font-inter text-[#161C2D] opacity-80'>The Storey Building, Adjacent GCB - Kantamanto Branch, Greater Accra, Ghana 🇬🇭</p>
                                <div className='w-full h-[1px] bg-[#D2D2D2] my-5' />
                                <p className='text-lg font-inter text-[#161C2D] opacity-80'>Room 1507, Tianxing Jinzuan, No. 555 Xintian Road, Yinzhou District, Ningbo City, China🇨🇳</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full' data-aos="fade-left">
                        <p className='text-xl text-[#161C2D] opacity-80'>Feel free to drop us a message</p>
                        <div className='w-full bg-white rounded-lg p-5 md:p-10 shadow-md mt-2'>
                            <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
                                <div className='w-full flex items-center gap-5'>
                                    <div className='flex-1'>
                                        <p className='font-montserrat text-base font-bold text-[#161C2D] mb-2'>Full Name</p>
                                        <input required type='text' id='fullname' name='fullname' className='h-[45px] w-full outline-none border border-[#E7E9ED] rounded-lg px-5' placeholder='Your full name eg. John Doe' />
                                    </div>
                                    <div className='flex-1'>
                                        <p className='font-montserrat text-base font-bold text-[#161C2D] mb-2'>Email</p>
                                        <input required type='email' id='email' name='email' className='h-[45px] w-full outline-none border border-[#E7E9ED] rounded-lg px-5' placeholder='Your email eg. john@yishipping.com' />
                                    </div>
                                </div>
                                <div>
                                    <p className='font-montserrat text-base font-bold text-[#161C2D] mb-2'>Phone Number</p>
                                    <input required type='number' id='phone' name='phone' className='h-[45px] w-full outline-none border border-[#E7E9ED] rounded-lg px-5' placeholder='Your contact eg. +233 01 234 5678' />
                                </div>
                                <div>
                                    <p className='font-montserrat text-base font-bold text-[#161C2D] mb-2'>Message</p>
                                    <textarea required type='text' id='message' name='message' className='h-[150px] w-full outline-none border border-[#E7E9ED] rounded-lg px-5 py-3' placeholder='Type your message' />
                                </div>
                                {!state.succeeded && <button type='submit' disabled={state.submitting} className='ml-auto h-[50px] w-fit px-20 text-white bg-primary rounded-lg font-bold'>{state.submitting ? 'Sending...' : 'Send'}</button>}
                                {state.errors && <p className='text-sm md:text-base text-center font-inter text-[#ff0000]'>Error Sending Information. Try again later or send an email to <br /> <span className='font-medium'>yishipping.gh@gmail.com</span></p>}
                                {state.succeeded && <p className='text-base text-center font-inter text-[#006400]'><span className='font-semibold'>Sent Successfully 🚀</span> <br /> You will be reached out to shortly</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs