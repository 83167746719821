import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'

import { STEPS } from '../../../../constants/steps.constants';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

function Steps() {
    const [selected, setSelected] = useState();

    const handleClick = (value) => {
        if(selected === value){
            setSelected()
        }else{
            setSelected(value)
        }
    }

    const Item = ({icon, title, bg_color, subText, isSelected, handleSelect}) => {
        return(
            <div className={`w-full px-[20px] py-[15px] rounded-xl bg-white overflow-hidden border ${isSelected ? 'border-primary' : 'border-white'}`}>
                <div className='w-full flex items-center gap-2 md: gap-5'>
                    <div className={`${bg_color} rounded-lg lg:rounded-2xl h-[40px] w-[40px] lg:h-[70px] lg:w-[70px] flex items-center justify-center mt-2`}> <img className='h-[25px] lg:h-[40px]' src={icon} alt={title} /> </div>
                    <h3 className='text-[#170F49] text-xl font-semibold font-montserrat text-3xl flex-1'>{title}</h3>
                    {isSelected && <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center text-white bg-primary text-base md:text-xl cursor-pointer' onClick={handleSelect}> <FiChevronDown /> </div>}
                    {!isSelected && <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center text-primary bg-white text-base md:text-xl cursor-pointer shadow-lg' onClick={handleSelect}> <FiChevronRight /> </div>}
                </div>
                <AnimatePresence>
                    {isSelected && (
                        <motion.div
                            initial={{ height: '0px' }}
                            animate={{ height: '' }}
                            exit={{ height: '0px' }}
                        >
                            <p className='my-3 text-[#636363] md:pr-[100px] leading-[150%] text-base md:text-lg md:leading-[150%]'>{subText}</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        )
    }

    return (
        <div className='bg-[#F5F5F5] px-[20px] py-20'>
            <div className='mx-auto max-w-[890px]'>
                <h1 className='max-w-[450px] mx-auto text-center font-montserrat text-3xl md:text-5xl font-extrabold'>Steps to ship with Yi Logistics</h1>
                <div className='flex flex-col gap-5 mt-10'>
                    {STEPS.map((i, idx) => (
                        <Item
                            icon={i.icon}
                            title={i.title}
                            bg_color={i.bg_color}
                            subText={i.subText}
                            isSelected={selected === idx + 1}
                            handleSelect={() => handleClick(idx + 1)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Steps