import React from 'react'
import { motion, AnimatePresence } from "framer-motion"

const images = [
    'https://res.cloudinary.com/ahumareze/image/upload/v1722305295/person_mbd3i4.jpg',
    'https://res.cloudinary.com/ahumareze/image/upload/v1722305295/ship-coursel-1_vxt1ie.jpg',
    'https://res.cloudinary.com/ahumareze/image/upload/v1722305295/bus_u6n7mg.jpg',
    'https://res.cloudinary.com/ahumareze/image/upload/v1722305292/mountails-coursel-2_ql5qlo.jpg',
    'https://res.cloudinary.com/ahumareze/image/upload/v1722305292/Rectangle_204_pgn4db.png'
]

function Images({index}) {
    
    return (
        <div className='flex-1 max-w-[700px] mx-auto h-[350px] mb-20 flex items-center justify-center relative'>
            {images.map((image, idx) => (
                <AnimatePresence>
                    {index === idx && (
                        <motion.div
                            initial={{ filter: 'blur(10px)', scale: 0.9, opacity: 0.7}}
                            animate={{ filter: 'blur(0px)', scale: 1, opacity: 1 }}
                            exit={{ filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                            key={index}
                            transition={{ duration: 0.8 }}
                            className='absolute top-0 left-0 right-0 bottom-0'
                        >
                            <img className='h-[350px] w-[700px] rounded-xl object-cover' src={image} alt='' />
                        </motion.div>
                    )}
                </AnimatePresence>
            ))}
            
        </div>
    )
}

export default Images