import step_1 from '../assets/icons/step_1.png';
import step_2 from '../assets/icons/step_2.png';
import step_3 from '../assets/icons/step_3.png';
import step_4 from '../assets/icons/step_4.png';
import step_5 from '../assets/icons/step_5.png';
import step_6 from '../assets/icons/step_6.png';
import step_7 from '../assets/icons/step_7.png';

export const STEPS = [
    {
        icon: step_1,
        bg_color: 'bg-step-one',
        title: `Step 1 - Acquisition of Product(s)`,
        subText: `Identify and source the required goods or products from suppliers or manufacturers in China ensuring they meet your specifications.`
    },
    {
        icon: step_2,
        bg_color: 'bg-step-two',
        title: `Step 2 - Payment Processing`,
        subText: `Complete financial transactions with suppliers, ensuring timely and accurate payment for procured goods, typically through agreed-upon payment methods such as wire transfers`
    },
    {
        icon: step_3,
        bg_color: 'bg-step-three',
        title: `Step 3 - Chinese Warehouse Delivery`,
        subText: `Arrange for transportation of procured goods to our warehouse in China, coordinating logistics and ensuring goods arrive safely and on schedule.`
    },
    {
        icon: step_4,
        bg_color: 'bg-step-four',
        title: `Step 4 - Loading & Shipping`,
        subText: `Organize the loading of goods onto shipping containers and arrange for their transportation from the Chinese warehouse to the port of departure, ensuring compliance with shipping regulations and documentation requirements.`
    },
    {
        icon: step_5,
        bg_color: 'bg-step-five',
        title: `Step 5 - Port Customs Clearance`,
        subText: `Facilitate the clearance of goods through customs procedures at the port of departure, ensuring all necessary documentation is provided and any applicable duties or taxes are paid to enable the goods to be shipped to Ghana.`
    },
    {
        icon: step_6,
        bg_color: 'bg-step-six',
        title: `Step 6 - Ghana Warehouse Unloading`,
        subText: `Coordinate the unloading of goods from the shipping containers upon arrival at the Ghanaian port arranging for transportation to our warehouse in Ghana.`
    },
    {
        icon: step_7,
        bg_color: 'bg-step-seven',
        title: `Step 7 - Customer Location Delivery`,
        subText: `Arrange for the final delivery of goods to the customer's location in Ghana, either through direct delivery or coordination for the customer to pick up the goods, ensuring customer satisfaction and timely receipt of the ordered products.`
    }
]