import React from 'react';

import services_img_1 from '../../../../assets/images/services_img_1.jpg';
import services_img_2 from '../../../../assets/images/services_img_2.jpg';
import services_img_3 from '../../../../assets/images/services_img_3.jpg';
import services_img_4 from '../../../../assets/images/services_img_4.jpg';
import services_img_5 from '../../../../assets/images/services_img_5.jpg';
import services_img_6 from '../../../../assets/images/services_img_6.jpg';
import services_img_7 from '../../../../assets/images/services_img_7.jpg';
import services_img_8 from '../../../../assets/images/services_img_8.jpg';
import services_img_9 from '../../../../assets/images/services_img_9.jpg';
import services_img_10 from '../../../../assets/images/services_img_10.jpg';

function Services() {

    return (
        <div className='w-full bg-white' id='services'>
            <div className='bg-white py-10 md:py-20 text-center px-[20px]'>
                <h1 className='text-3xl md:text-4xl font-extrabold font-montserrat'>Services We Offer</h1>
                <p className='font-inter text-lg md:text-xl max-w-[800px] mx-auto mt-3 text-[#636363]'>Experience a smooth and hassle-free process of delivering packages to the destination of your choice effortlessly with Yi Logistics.</p>
            </div>
            <div className='w-full py-10 px-[20px]'>
                <div className='max-w-[1050px] mx-auto md:grid md:grid-cols-2 gap-[45px]'>
                    <div>
                        <h2 className='text-3xl md:text-4xl font-montserrat font-medium'>International <br /> Shipping</h2>
                        <p className='text-lg font-inter text-[#636363] mt-4'>We handle the transportation and logistics of your goods across borders to guarantee a secure and timely delivery</p>
                        <img data-aos="fade-up" src={services_img_1} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-10 w-full bg-[#ddd] rounded-lg' />
                    </div>
                    <img data-aos="fade-up" src={services_img_2} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-5 md:mt-0 w-full bg-[#ddd] rounded-lg' />
                </div>
            </div>
            <div className='w-full py-10 bg-[#F5F5F5] px-[20px]'>
                <div className='max-w-[1050px] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 md:gap-[45px]'>
                    <img data-aos="fade-up" src={services_img_3} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-5 md:mt-0 w-full bg-[#ddd] rounded-lg' />
                    <div>
                        <h2 className='text-3xl md:text-4xl font-montserrat font-medium'>Air <br /> Transportation</h2>
                        <p className='text-lg font-inter text-[#636363] mt-4'>Air delivery of your goods and packages is a very simple and convenient process with us</p>
                        <img data-aos="fade-up" src={services_img_4} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-10 w-full bg-[#ddd] rounded-lg' />
                    </div>
                </div>
            </div>
            <div className='w-full py-10 px-[20px]'>
                <div className='max-w-[1050px] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 md:gap-[45px]'>
                    <img data-aos="fade-up" src={services_img_5} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-5 md:mt-0 w-full bg-[#ddd] rounded-lg' />
                    <div>
                        <h2 className='text-3xl md:text-4xl font-montserrat font-medium'>Purchasing <br /> Agent</h2>
                        <p className='text-lg font-inter text-[#636363] mt-4'>We locate suppliers to research goods and services and process your orders</p>
                        <img data-aos="fade-up" src={services_img_6} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-10 w-full bg-[#ddd] rounded-lg' />
                    </div>
                </div>
            </div>
            <div className='w-full py-10 bg-[#F5F5F5] px-[20px]'>
                <div className='max-w-[1050px] mx-auto md:grid md:grid-cols-2 gap-[45px]'>
                    <div>
                        <h2 className='text-3xl md:text-4xl font-montserrat font-medium'>Custom <br /> Clearance</h2>
                        <p className='text-lg font-inter text-[#636363] mt-4'>We ensure that all processes related to obtaining permits for the entry of your goods into a country are properly handled</p>
                        <img data-aos="fade-up" src={services_img_8} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-10 w-full bg-[#ddd] rounded-lg' />
                    </div>
                    <img data-aos="fade-up" src={services_img_7} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-5 md:mt-0 w-full bg-[#ddd] rounded-lg' />
                </div>
            </div>
            <div className='w-full py-10 px-[20px]'>
                <div className='max-w-[1050px] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 md:gap-[45px]'>
                <img data-aos="fade-up" src={services_img_10} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-5 md:mt-0 w-full bg-[#ddd] rounded-lg' />
                    <div>
                        <h2 className='text-3xl md:text-4xl font-montserrat font-medium'>Warehousing & <br /> Transportation</h2>
                        <p className='text-lg font-inter text-[#636363] mt-4'>We participate in the process of transferring your packages between countries and securely storing them.</p>
                        <img data-aos="fade-up" src={services_img_9} alt='yi logistics' className='h-[300px] object-cover md:h-[600px] mt-10 w-full bg-[#ddd] rounded-lg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services