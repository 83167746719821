import React from 'react';

import gh_flag from '../../../../assets/icons/gh.png';
import cn_flag from '../../../../assets/icons/cn.png';

import china_bg from '../../../../assets/bgImages/china_bg.svg';
import ghana_bg from '../../../../assets/bgImages/ghana_bg.svg';

function Warehouses() {
    return (
        <div className='bg-black w-full py-10 lg:py-20 px-[20px]' id='warehouses'>
            <div className='text-center'>
                <h1 className='text-3xl md:text-4xl font-bold font-montserrat text-white'>Our Warehouses</h1>
                <p className='font-inter text-xl md:text-2xl mt-2 max-w-[800px] mx-auto text-[#636363]'>Locate our warehouses in China and Ghana</p>
            </div>
            <div className='max-w-[1000px] mx-auto mt-10 flex flex-col md:grid md:grid-cols-[1fr_1px_1fr] items-center gap-4'>
                <div data-aos="fade-up" className='w-full md:h-full rounded-xl bg-[#161616] py-10 px-5 text-white bg-center bg-no-repeat bg-cover' style={{background: `url(${china_bg})`}}>
                    <img src={cn_flag} alt='' className='w-[120px]' />
                    <h2 className='text-2xl font-montserrat font-bold'>China</h2>
                    <p className='text-lg opacity-70 mt-2 font-inter'>We have warehouses in Yiwu, Ningbo, Taizhou and other places in China with a professional management team and a total storage area of more than 5,000 square meters to ensure that customers' goods can be properly kept after arriving at the China warehouse</p>
                    <div className='w-full flex items-bottom gap-2 mt-10'>
                        <div>
                            <h3 className='text-lg font-bold font-montserrat mb-2'>Locations</h3>
                            <p className='text-lg opacity-90'>Yiwu</p>
                            <p className='text-lg opacity-90'>Ningbo</p>
                            <p className='text-lg opacity-90'>Taizhou</p>
                        </div>
                        <div className='text-right flex-1'>
                            <h3 className='text-lg font-bold font-montserrat mb-2'>Office Address</h3>
                            <p className='text-lg opacity-90'>Room 1507, Tianxing Jinzuan,</p>
                            <p className='text-lg opacity-90'>No. 555 Xintian Road,</p>
                            <p className='text-lg opacity-90'>Yinzhou District, Ningbo City.</p>
                            <p className='text-lg opacity-90'>+86 13157972275</p>
                        </div>
                    </div>
                </div>
                <div className='h-[1px] md:h-[70%] w-[70%] md:w-full bg-[#434343] opacity-70' />
                <div data-aos="fade-up" className='w-full md:h-full rounded-xl bg-[#161616] py-10 px-5 text-white bg-center bg-no-repeat bg-cover' style={{background: `url(${ghana_bg})`}}>
                    <img src={gh_flag} alt='' className='w-[120px]' />
                    <h2 className='text-2xl font-montserrat font-bold'>Ghana</h2>
                    <p className='text-lg opacity-70 mt-2 font-inter'>In Accra, we have more than 1800 square meters of storage area. The goods delivered from the China warehouse will eventually be transported to the Accra warehouse. Customers can choose to go to the warehouse to pick up the goods in person, or we can arrange for the goods to be delivered to a designated location, providing you with more convenient options</p>
                    <div className='w-full flex items-bottom gap-2 mt-10'>
                        <div>
                            <h3 className='text-lg font-bold font-montserrat mb-2'>Locations</h3>
                            <p className='text-lg opacity-90'>Greater Accra</p>
                            <p className='text-lg opacity-90'>Kumasi</p>
                            <p className='text-lg opacity-90'>Tema</p>
                        </div>
                        <div className='text-right flex-1'>
                            <h3 className='text-lg font-bold font-montserrat mb-2'>Office Address</h3>
                            <p className='text-lg opacity-90'>3rd Floor, SSNIT Emporium,</p>
                            <p className='text-lg opacity-90'>Airport City, Greater Accra</p>
                            <p className='text-lg opacity-90'>+233 53 462 3340</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Warehouses