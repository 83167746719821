import React from 'react'
import Header from '../../../../components/header/Header'
import ship_bg from '../../../../assets/videos/ship_on_water.mp4'

import classes from './hero.module.css';
import { handleScroll } from '../../../../helpers';
import { PATHS } from '../../../../constants/paths.constants';

function  Hero() {
    return (
        <div>
            <Header selected={PATHS.HOME} />
            <div className='relative'>
                <div className='w-full h-[800px]'>
                    <video 
                        className='w-full h-full object-cover' 
                        src={ship_bg} 
                        autoPlay 
                        loop 
                        muted 
                    />
                </div>
                <div className={`bg-primary ${classes.container} absolute top-0 h-full w-full flex items-center`}>
                    <div className="w-full h-fit text-center text-white px-[20px]">
                        <h1 className="text-4xl md:text-[65px] font-extrabold mx-auto" data-aos="fade-up">Delivering Peace of Mind Everytime</h1>
                        {/* <p className="max-w-[590px] mx-auto text-xl md:text-2xl mt-5" data-aos="fade-up">Yi Logistics, your Global Logistics Partner for Seamless Shipping Solutions!</p> */}
                        <div className="flex items-center justify-center mx-auto gap-3 mt-10" data-aos="fade-up">
                            <button className="py-3 px-5 rounded-lg text-white hover:bg-button-30 font-inter font-semibold" onClick={() => handleScroll('intro_banner')} >Learn more</button>
                            <button className="py-3 px-5 rounded-lg bg-primary text-white font-inter font-semibold" onClick={() => handleScroll('contact_us')}>Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`h-[50px] w-full bg-primary ${classes.bottom_overlay} relative top-[-50px]`} /> */}
        </div>
    )
}

export default Hero