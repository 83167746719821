import React, { useState } from 'react';

import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import about_bg from '../../../assets/bgImages/about_bg.png';
import Images from './Images';


function Coursel() {
    const [selected, setSelected] = useState(1);

    const handleCourselButtons = (type) => {
        if(type === 'foward' && selected < 4){
            setSelected(prev => prev + 1)
        }else if(type === 'backward' && selected > 0){
            setSelected(prev => prev - 1)
            
        }
    }

    return (
        <div className='w-full px-[20px] md:px-[50px] py-10 md:py-20' style={{backgroundImage: `url(${about_bg})`}}>
            <div className='max-w-[1200px] mx-auto flex items-center gap-5'>
                <div className='h-[40px] w-[40px] bg-white hover:bg-[#f5f5f5] rounded-full flex items-center justify-center text-4xl text-[#BEBEBE]' onClick={() => handleCourselButtons('backward')}><IoMdArrowDropleft /></div>
                <div className='flex-1'>
                    <Images 
                        index={selected}
                    />
                    <div className='flex items-center justify-center gap-3'>
                        {[0,1,2,3,4].map(i => (
                            <div className={`h-[10px] w-[10px] rounded-full ${selected === i ? 'bg-primary' : 'bg-[#999999]'}`} />
                        ))}
                    </div>
                </div>
                <div className='h-[40px] w-[40px] bg-white hover:bg-[#f5f5f5] rounded-full flex items-center justify-center text-4xl text-[#BEBEBE]' onClick={() => handleCourselButtons('foward')}><IoMdArrowDropright /></div>
            </div>
            <div className='flex items-center justify-center gap-5'>
                {[0,1,2,3,4].map(i => (
                    <div className={`h-10px] w-[10px] rounded-full ${selected === i ? 'bg-primary' : 'bg-[#eee]'}`} />
                ))}
            </div>
        </div>
    )
}

export default Coursel