import React, { useEffect } from 'react'

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import ContactUs from '../home/components/contactUS/ContactUs';

function ContactPage() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <Header />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default ContactPage