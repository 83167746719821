import React, { useEffect } from 'react'

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Warehouses from '../home/components/warehouses/Warehouses';

function WarehousesPage() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <Header />
            <Warehouses />
            <Footer />
        </div>
    )
}

export default WarehousesPage