import React from 'react';

import white_logo from '../../assets/icons/white_logo.svg';
import instagram from '../../assets/icons/instagram.svg';
import facebook from '../../assets/icons/facebook.svg';
import twitter from '../../assets/icons/twitter.svg';
import linkedin from '../../assets/icons/linkedin.svg';

import yi_black_container from '../../assets/bgImages/yi_black_container.svg';
import { Link } from 'react-router-dom';
import { PATHS } from '../../constants/paths.constants';
import { IoCall, IoMail } from "react-icons/io5";
import { IoMdPin } from "react-icons/io";

function Footer() {
    return (
        <div className='w-full bg-[#054584] py-10 px-[20px] lg:py-20 relative overflow-hidden'>
            <div className='max-w-[1200px] mx-auto text-white'>
                <div className='flex flex-col md:flex-row gap-10 lg:gap-20 pb-10 lg:pb-20 border-b border-b-[#eee]'>
                    <div className='flex-1'>
                        <h2 className='font-montserrat text-2xl md:text-3xl font-bold'>Ready to ship your next set of goods?</h2>
                        <p className='opacity-65 text-lg md:text-xl mt-2 font-inter max-w-[630px]'>Yi Logistics is your ultimate destination, offering unparalleled shipping and logistics services to bring you a sense of tranquility.</p>
                    </div>
                    <Link to={PATHS.CONTACT}><button className='px-10 bg-white h-[55px] rounded-lg text-primary font-inter font-bold'>Reach out to us</button></Link>
                </div>
                <div className='pt-10 lg:pt-20 w-full flex flex-col lg:flex-col'>
                    <div className='lg:flex-1 flex gap-10 lg:gap-20'>
                        <div className='flex-1'>
                            <Link to={PATHS.HOME}><img src={white_logo} alt='yi_logistics_logo' className='max-w-[200px]' /></Link>
                            <div className='mt-7 flex flex-col gap-3'>
                                <div className='flex items-center gap-2'> <div className='h-[22px] w-[22px] rounded-full text-primary bg-white flex items-center justify-center text-sm'><IoCall /></div><p className='text-base color-white opacity-75 flex-1'>+86 13157972275</p></div>
                                <div className='flex gap-2'> <div className='h-[22px] w-[22px] rounded-full text-primary bg-white flex items-center justify-center text-sm mt-2'><IoMdPin /></div><p className='text-base color-white opacity-75 flex-1'>Room 1507, Tianxing Jinzuan, No. 555 Xintian Road, Yinzhou District, Ningbo City, China🇨🇳</p></div>
                            </div>
                            <div className='mt-5 flex flex-col gap-3 border-t border-[#D2D2D2] pt-5'>
                                <div className='flex items-center gap-2'> <div className='h-[22px] w-[22px] rounded-full text-primary bg-white flex items-center justify-center text-sm'><IoCall /></div><p className='text-base color-white opacity-75 flex-1'>+233 53 462 3340</p></div>
                                <div className='flex gap-2'> <div className='h-[22px] w-[22px] rounded-full text-primary bg-white flex items-center justify-center text-sm mt-2'><IoMdPin /></div><p className='text-base color-white opacity-75 flex-1'>The Storey Building, Adjacent GCB - Kantamanto Branch, Greater Accra, Ghana🇬🇭</p></div>
                                <div className='flex items-center gap-2'> <div className='h-[22px] w-[22px] rounded-full text-primary bg-white flex items-center justify-center text-sm'><IoMail /></div><p className='text-base color-white opacity-75 flex-1'>yishipping.gh@gmail.com</p></div>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <p className='opacity-60 font-inter'>Quick Links</p>
                            <div className='flex flex-col gap-5 mt-7'>
                                <Link to={PATHS.ABOUT} className='text-lg font-inter cursor-pointer'>About us</Link>
                                <Link to={PATHS.SERVICES} className='text-lg font-inter cursor-pointer'>Our Services</Link>
                                <Link to={PATHS.WHY_US} className='text-lg font-inter cursor-pointer'>Why Yi Logistics</Link>
                                <Link to={PATHS.WAREHOUSES} className='text-lg font-inter cursor-pointer'>Warehouses</Link>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <p className='opacity-60 font-inter'>Socials</p>
                            <div className='flex flex-col gap-5 mt-7'>
                                <Link to='https://www.instagram.com/yishipping/' target='_blank' rel="noreferrer" className='flex items-center gap-2'><img src={instagram} alt='' className='h-[28px]' /> <p className='text-base'>Instagram</p></Link>
                                <Link to='https://www.facebook.com/profile.php?id=61555406002303' target='_blank' rel="noreferrer" className='flex items-center gap-2'><img src={facebook} alt='' className='h-[28px]' /> <p className='text-base'>Facebook</p></Link>
                                <Link to='' target='_blank' rel="noreferrer" className='flex items-center gap-2'><img src={twitter} alt='' className='h-[28px]' /> <p className='text-base'>X</p></Link>
                                <Link to='' target='_blank' rel="noreferrer" className='flex items-center gap-2'><img src={linkedin} alt='' className='h-[28px]' /> <p className='text-base'>Linkedin</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className='lg:flex-1 hidden md:block pt-[200px]'>
                        <img src={yi_black_container} alt='' className='h-[430px] absolute bottom-[-200px] right-[-150px]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer