import React, { useEffect } from 'react'

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer';

// import about_bg from '../../assets/bgImages/about-bg.png';
import Coursel from './components/Coursel';
import { PATHS } from '../../constants/paths.constants';

function About() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <Header selected={PATHS.ABOUT} />
            {/* <div className='h-fit py-20 px-[20px] md:h-screen w-full bg-[#F4F7FA] flex items-center justify-center bg-no-repeat bg-center bg-cover' style={{backgroundImage: `url(${about_bg})`}}>
                <Computer />
            </div> */}
            <div className='w-full px-[20px] md:px-[50px] py-10 md:py-20'>
                <div className='max-w-[1200px] mx-auto'>
                    <h1 className='text-2xl md:text-5xl font-montserrat font-bold' data-aos="fade-up">About Yi Logistics</h1>
                    <p className='text-[#161C2DB2] opacity-80 mt-5 text-lg md:text-2xl' data-aos="fade-up">Yi Logistics has been actively operating in Ghana for more than 7 years. In 2023, we officially established our Ghana branch, with offices and warehouses strategically located in Accra. Our objective is to deliver efficient and dependable logistics services to our valued customers in Ghana.</p>
                    <p className='text-[#161C2DB2] opacity-80 mt-5 text-lg md:text-2xl' data-aos="fade-up">Zhejiang Yimiao International Logistics was established in 2015 with a registered capital of 10 million yuan and has NVOCC qualifications. The company's business covers international freight by sea, land and air, import and export agency, warehousing, transportation, and information consulting. The company continues to innovate in the field of international logistics and strives to grow into a world-class integrated logistics service company supported by sea, land and air freight systems that serves the world.</p>
                </div>
            </div>
            <Coursel />
            <Footer />
        </div>
    )
}

export default About