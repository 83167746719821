import React from 'react';

import globe_bg from '../../../../assets/bgImages/globe_bg.svg';
import yi_blue_container from '../../../../assets/bgImages/yi_blue_container.svg';

function IntroBanner() {
    return (
        <div className="w-full h-fit bg-white mb-[-100px]" style={{background: `url(${globe_bg})`}} id='intro_banner'>
            <div className="py-20 px-[20px] text-center w-full">
                <h1 className="max-w-[570px] mx-auto font-extrabold text-4xl md:text-5xl font-montserrat text-[#054584]">Delivering your packages <span className="text-[#979797]">effortlessly</span></h1>
                <p className="max-w-[1000px] mx-auto text-xl md:text-2xl text-[#636363] mt-5 font-inter">Experience a smooth and hassle-free process of delivering packages to the destination of your choice effortlessly with Yi Logistics.</p>
                <div className=" w-full flex mt-10 justify-center" data-aos="fade-up">
                    <img
                        src={yi_blue_container}
                        alt=""
                    />
                </div>
            </div> 
        </div>
    )
}

export default IntroBanner