import React, { useEffect } from 'react'

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Services from '../home/components/services/Services'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Steps from './components/steps/Steps';
import { PATHS } from '../../constants/paths.constants';

function ServicesPage() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <Header selected={PATHS.SERVICES} />
            <Services />
            <Steps />
            <Footer />
        </div>
    )
}

export default ServicesPage